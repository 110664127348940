import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import HeadSection from '~/components/blocks/HeadSection'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import OurCoreValues from '~/mock/homepage/OurCoreValues'
import { cx, fileToImageLikeData } from '~/utils'

import useHomepageStaticQuery from '../../useHomepageStaticQuery'

import * as containerStyles from './OurCoreValuesSection.module.scss'

const OurCoreValuesSection = () => {
  const query = useHomepageStaticQuery()

  const ourCoreTeam = getImage(fileToImageLikeData(query.ourCoreTeam))
  const ourCoreTeamLg = getImage(fileToImageLikeData(query.ourCoreTeamLg))

  return (
    <div className={containerStyles.homeCoreValuesSection}>
      <div className={containerStyles.homeCoreValuesSection__head}>
        <HeadSection position="left" title="Our core values" />
      </div>

      <ServicesIndustriesCard dataContent={OurCoreValues} />

      <div className="container container-md">
        <div
          className={cx(
            'row',
            containerStyles.homeCoreValuesSection__imageWrapper,
          )}
        >
          <div className="col-12 col-md-5">
            <div className={containerStyles.homeCoreValuesSection__image}>
              <GatsbyImage
                image={ourCoreTeam as IGatsbyImageData}
                alt="Focus on proven results | Codica"
                title="Focus on proven results at Codica"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-7 d-none d-md-block">
            <GatsbyImage
              image={ourCoreTeamLg as IGatsbyImageData}
              alt="Great and talented people | Codica"
              title="Great and talented people at Codica"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurCoreValuesSection
