// extracted by mini-css-extract-plugin
export var homeWebDevProcess = "cl_pr";
export var homeWebDevProcess__between = "cl_ps";
export var homeWebDevProcess__contentFive = "cl_pC";
export var homeWebDevProcess__contentFour = "cl_pB";
export var homeWebDevProcess__contentOne = "cl_px";
export var homeWebDevProcess__contentThree = "cl_pz";
export var homeWebDevProcess__contentTwo = "cl_py";
export var homeWebDevProcess__description = "cl_pw";
export var homeWebDevProcess__image = "cl_pt";
export var homeWebDevProcess__title = "cl_pv";