import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

export const useLeadershipQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      photoFounder: file(relativePath: { eq: "leadership/ceo.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoCoFounder: file(relativePath: { eq: "leadership/co-founder.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoSalesDir: file(
        relativePath: { eq: "leadership/sales-director.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoRoRLead: file(relativePath: { eq: "leadership/ror-lead.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoRoRLead_2: file(relativePath: { eq: "leadership/ror-lead-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoFrontLead: file(relativePath: { eq: "leadership/front-lead.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoFrontLead_2: file(
        relativePath: { eq: "leadership/front-lead-2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoPmLead: file(relativePath: { eq: "leadership/pm-lead.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoDesignLead: file(
        relativePath: { eq: "leadership/design-lead.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoQALead: file(relativePath: { eq: "leadership/qa-lead.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoSalesManager: file(
        relativePath: { eq: "leadership/sales-manager.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      photoHr: file(relativePath: { eq: "leadership/hr.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}
