// extracted by mini-css-extract-plugin
export var projectsLogo__lazy = "fq_Lr";
export var projectsLogo__logo = "fq_Lq";
export var projectsLogo__logo_1 = "fq_Ls";
export var projectsLogo__logo_2 = "fq_Lt";
export var projectsLogo__logo_3 = "fq_Lv";
export var projectsLogo__logo_4 = "fq_Lw";
export var projectsLogo__logo_5 = "fq_Lx";
export var projectsLogo__logo_6 = "fq_Ly";
export var projectsLogo__logo_7 = "fq_Lz";
export var projectsLogo__logo_8 = "fq_LB";
export var projectsLogo__logos = "fq_Lp";
export var projectsLogo__wrapper = "fq_Ln";