import CompanyProjectsLogo from '~/components/blocks/CompanyProjectsLogo'
import HeadSection from '~/components/blocks/HeadSection'

import * as containerStyles from './CompanyProjectsLogoSection.module.scss'

const CompanyProjectsLogoSection = () => (
  <div className={containerStyles.homeProjectsLogoSection}>
    <div className={containerStyles.homeProjectsLogoSection__headSection}>
      <HeadSection
        position="left"
        title="Companies that trust us"
        description="Clients and partners we’ve worked with frequently recommend us to others thanks to our individual approach to tech problems"
      />
    </div>
    <CompanyProjectsLogo />
  </div>
)

export default CompanyProjectsLogoSection
