// extracted by mini-css-extract-plugin
export var companyInfo__card = "ch_n9";
export var companyInfo__cardText = "ch_pc";
export var companyInfo__cardTitle = "ch_pb";
export var companyInfo__description = "ch_n8";
export var companyInfo__flex = "ch_pj";
export var companyInfo__head = "ch_ph";
export var companyInfo__headSm = "ch_pg";
export var companyInfo__title = "ch_n7";
export var companyInfo__topWrapper = "ch_pf";
export var companyInfo__wrapper = "ch_pd";