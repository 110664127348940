import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import { LeadershipData } from '~/types/data-array'
import { fileToImageLikeData } from '~/utils'

import * as containerStyles from './Leadership.module.scss'
import { useLeadershipQuery } from './useLeadershipQuery'

const Leadership = () => {
  const query = useLeadershipQuery()

  const leadershipData: LeadershipData[] = [
    {
      photo: getImage(fileToImageLikeData(query.photoFounder)),
      name: 'Dmytro',
      position: 'CEO & Founder',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoCoFounder)),
      name: 'Natalia',
      position: 'Co-Founder & HR Business Partner',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoSalesDir)),
      name: 'Oleksii',
      position: 'Growth & Sales Director',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoRoRLead)),
      name: 'Serhii',
      position: 'Tech Lead & Rails expert',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoRoRLead_2)),
      name: 'Max',
      position: 'Tech Lead & Rails expert',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoFrontLead)),
      name: 'Eugeny',
      position: 'Tech Lead & React expert',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoFrontLead_2)),
      name: 'Dmytro',
      position: 'Tech Lead & React / Node.js expert',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoPmLead)),
      name: 'Arpi',
      position: 'PM Team Lead',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoDesignLead)),
      name: 'Inessa',
      position: 'UI/UX Lead',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoQALead)),
      name: 'Oleksii',
      position: 'QA Team Lead',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoSalesManager)),
      name: 'Taya',
      position: 'Sales Manager',
    },
    {
      photo: getImage(fileToImageLikeData(query.photoHr)),
      name: 'Tatiana',
      position: 'HR Team Lead',
    },
  ]

  return (
    <div className="container container-md">
      <div className="row">
        {leadershipData.map(({ photo, name, position }, index) => (
          <div key={index} className="col-12 col-md-6 col-lg-4">
            <div className={containerStyles.leadership__box}>
              <GatsbyImage
                image={photo as IGatsbyImageData}
                alt={`${name}, ${position} at Codica`}
                title={`${name}, ${position}`}
                loading="lazy"
              />
              <div className={containerStyles.leadership__name}>{name}</div>
              <div className={containerStyles.leadership__position}>
                {position}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Leadership
