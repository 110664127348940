// extracted by mini-css-extract-plugin
export var achievementsSlider__badge_1 = "fp_K1";
export var achievementsSlider__badge_10 = "fp_Lb";
export var achievementsSlider__badge_11 = "fp_Lc";
export var achievementsSlider__badge_12 = "fp_Ld";
export var achievementsSlider__badge_13 = "fp_Lf";
export var achievementsSlider__badge_14 = "fp_Lg";
export var achievementsSlider__badge_15 = "fp_Lh";
export var achievementsSlider__badge_16 = "fp_Lj";
export var achievementsSlider__badge_17 = "fp_Lk";
export var achievementsSlider__badge_18 = "fp_Ll";
export var achievementsSlider__badge_19 = "fp_Lm";
export var achievementsSlider__badge_2 = "fp_K2";
export var achievementsSlider__badge_3 = "fp_K3";
export var achievementsSlider__badge_4 = "fp_K4";
export var achievementsSlider__badge_5 = "fp_K5";
export var achievementsSlider__badge_6 = "fp_K6";
export var achievementsSlider__badge_7 = "fp_K7";
export var achievementsSlider__badge_8 = "fp_K8";
export var achievementsSlider__badge_9 = "fp_K9";
export var achievementsSlider__logo = "fp_K0";
export var achievementsSlider__swiperSliderWrapper = "fp_KZ";