import { useAddClassNameAfterMount } from '~/hooks/useAddClassNameAfterMount'
import { cx } from '~/utils'

import * as containerStyles from './CompanyProjectsLogo.module.scss'

const projectsLogo = [
  { logo: 'logo_1' },
  { logo: 'logo_2' },
  { logo: 'logo_3' },
  { logo: 'logo_4' },
  { logo: 'logo_5' },
  { logo: 'logo_6' },
  { logo: 'logo_7' },
  { logo: 'logo_8' },
]

const CompanyProjectsLogo = () => {
  const ref = useAddClassNameAfterMount<HTMLDivElement>({
    targetClassName: containerStyles.projectsLogo__lazy,
    loadedClassName: containerStyles.projectsLogo__logo,
  })

  return (
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div ref={ref} className={containerStyles.projectsLogo__wrapper}>
            {projectsLogo.map(({ logo }) => (
              <div key={logo} className={containerStyles.projectsLogo__logos}>
                <div
                  className={cx(
                    containerStyles.projectsLogo__lazy,
                    containerStyles[`projectsLogo__${logo}`],
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyProjectsLogo
