// extracted by mini-css-extract-plugin
export var succesStoriesCard = "fC_Ms";
export var succesStoriesCardRow = "fC_MD";
export var succesStoriesCard__bottomWrapper = "fC_My";
export var succesStoriesCard__category = "fC_MB";
export var succesStoriesCard__categoryWrapper = "fC_Mz";
export var succesStoriesCard__description = "fC_Mx";
export var succesStoriesCard__image = "fC_Mv";
export var succesStoriesCard__locaation = "fC_MC";
export var succesStoriesCard__logosWrapper = "fC_Mw";
export var succesStoriesCard__title = "fC_Mt";