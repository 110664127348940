import { HomeSpecification } from '~/types/mock'

const OurCoreValues: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_1',
    title: 'Focus on proven results',
    description: 'Your growth starts with our solution',
  },
  {
    icon: 'sprite3DIconSmall_2',
    title: 'Great and talented people',
    description: 'We love to create and create what we love',
  },
  {
    icon: 'sprite3DIconSmall_3',
    title: 'Continuous improvement',
    description:
      'We refine our skills to deliver better products Every. Single. Time',
    maxWidthDescription: '350px',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Reliable partners to our clients',
    maxWidthTitle: '220px',
    description: 'Entrust us with your idea, and you’ll get a robust product',
  },
  {
    icon: 'sprite3DIconSmall_5',
    title: 'Transparent communication',
    description: 'We’ve got you covered with any adjustments at any step',
  },
  {
    icon: 'sprite3DIconSmall_6',
    title: 'Mutual trust and responsibility',
    description: 'We keep your product safe from concept to launch and support',
  },
]

export default OurCoreValues
