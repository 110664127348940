import * as containerStyles from './CompanyInfo.module.scss'

interface CompanyInfoProps {
  title?: string
  isInfo?: boolean
  description?: string
}

const CompanyInfo = ({ title, description, isInfo }: CompanyInfoProps) => (
  <div className="container container-md">
    <div className="row">
      <div className="col-12 col-lg-6">
        {title && (
          <div className={containerStyles.companyInfo__title}>{title}</div>
        )}

        {description && (
          <div className={containerStyles.companyInfo__description}>
            {description}
          </div>
        )}

        {isInfo && (
          <div className={containerStyles.companyInfo__wrapper}>
            <div className={containerStyles.companyInfo__topWrapper}>
              <div className={containerStyles.companyInfo__headSm}>
                Specialization
              </div>

              <div className={containerStyles.companyInfo__head}>
                Custom web product development
              </div>
            </div>

            <div className={containerStyles.companyInfo__flex}>
              <div>
                <div className={containerStyles.companyInfo__headSm}>
                  Development team
                </div>
                <div className={containerStyles.companyInfo__head}>
                  Remote, Ukraine
                </div>
              </div>

              <div>
                <div className={containerStyles.companyInfo__headSm}>
                  Representative office
                </div>
                <div className={containerStyles.companyInfo__head}>
                  Tallinn, Estonia
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="col-12 col-lg-6">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className={containerStyles.companyInfo__card}>
              <div className={containerStyles.companyInfo__cardTitle}>9</div>
              <div className={containerStyles.companyInfo__cardText}>
                Years in the market
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className={containerStyles.companyInfo__card}>
              <div className={containerStyles.companyInfo__cardTitle}>50+</div>
              <div className={containerStyles.companyInfo__cardText}>
                Successful projects
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className={containerStyles.companyInfo__card}>
              <div className={containerStyles.companyInfo__cardTitle}>+56$</div>
              <div className={containerStyles.companyInfo__cardText}>
                Mln raised by our startup clients
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className={containerStyles.companyInfo__card}>
              <div className={containerStyles.companyInfo__cardTitle}>60+</div>
              <div className={containerStyles.companyInfo__cardText}>
                Team size
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default CompanyInfo
