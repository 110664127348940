import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useHomepageStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      ourCoreTeam: file(relativePath: { eq: "homepage/our-core team.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      ourCoreTeamLg: file(relativePath: { eq: "homepage/our-core team.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      successStories_1: file(
        relativePath: { eq: "homepage/success-stories-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      successStories_2: file(
        relativePath: { eq: "homepage/success-stories-2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      successStories_3: file(
        relativePath: { eq: "homepage/success-stories-3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      successStories_4: file(
        relativePath: { eq: "homepage/success-stories-4.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useHomepageStaticQuery
